exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-compost-a-pak-commercial-js": () => import("./../../../src/pages/compost-a-pak-commercial.js" /* webpackChunkName: "component---src-pages-compost-a-pak-commercial-js" */),
  "component---src-pages-compost-a-pak-js": () => import("./../../../src/pages/compost-a-pak.js" /* webpackChunkName: "component---src-pages-compost-a-pak-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-native-flora-js": () => import("./../../../src/pages/native-flora.js" /* webpackChunkName: "component---src-pages-native-flora-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-services-community-asset-auditing-js": () => import("./../../../src/pages/services/community-asset-auditing.js" /* webpackChunkName: "component---src-pages-services-community-asset-auditing-js" */),
  "component---src-pages-services-custom-build-projects-js": () => import("./../../../src/pages/services/custom-build-projects.js" /* webpackChunkName: "component---src-pages-services-custom-build-projects-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-retrofit-recycling-solutions-js": () => import("./../../../src/pages/services/retrofit-recycling-solutions.js" /* webpackChunkName: "component---src-pages-services-retrofit-recycling-solutions-js" */),
  "component---src-pages-services-rollout-and-distribution-programs-js": () => import("./../../../src/pages/services/rollout-and-distribution-programs.js" /* webpackChunkName: "component---src-pages-services-rollout-and-distribution-programs-js" */),
  "component---src-pages-services-single-use-plastic-free-freight-js": () => import("./../../../src/pages/services/single-use-plastic-free-freight.js" /* webpackChunkName: "component---src-pages-services-single-use-plastic-free-freight-js" */),
  "component---src-templates-products-category-js": () => import("./../../../src/templates/products-category.js" /* webpackChunkName: "component---src-templates-products-category-js" */),
  "component---src-templates-single-blog-js": () => import("./../../../src/templates/single-blog.js" /* webpackChunkName: "component---src-templates-single-blog-js" */),
  "component---src-templates-single-compost-a-pak-product-js": () => import("./../../../src/templates/single-compost-a-pak-product.js" /* webpackChunkName: "component---src-templates-single-compost-a-pak-product-js" */),
  "component---src-templates-single-product-js": () => import("./../../../src/templates/single-product.js" /* webpackChunkName: "component---src-templates-single-product-js" */)
}

